// src/utils/api.js

export const API_BASE_URL = "https://nimo-backend.azurewebsites.net";

// API endpoints
export const ENDPOINTS = {
  PAGES: "/pages",
  SCENARIOS: "/scenarios",
  RETELL_AGENTS: "/retell-agents",
  CREATE_RETELL_AGENT: "/create_retell_agent",
  UPDATE_AGENT: "/update-agent",
  DELETE_AGENT: "/delete-agent",
  VOICES: "/voices",
  LLMS: "/llms",
};

// Helper function to construct full API URL
export const getApiUrl = (endpoint) => `${API_BASE_URL}${endpoint}`;

/**
 * Fetch all agents.
 * @returns {Promise<Object>} The agents data.
 */
export const fetchAgents = async () => {
  const response = await fetch(getApiUrl(ENDPOINTS.RETELL_AGENTS));

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch agents: ${errorText}`);
  }

  return response.json(); // Expected to return { agents: [...] }
};

/**
 * Delete an agent by ID.
 * @param {string} agentId - The ID of the agent to delete.
 * @returns {Promise<Object>} The deletion response.
 */
export const deleteAgent = async (agentId) => {
  const response = await fetch(
    getApiUrl(`${ENDPOINTS.DELETE_AGENT}/${agentId}`),
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to delete agent: ${errorText}`);
  }

  return response.json();
};

/**
 * Create a new agent with associated LLM data.
 * @param {Object} data - The agent and LLM data.
 * @returns {Promise<Object>} The created agent response.
 */
export const createAgent = async (data) => {
  const response = await fetch(getApiUrl(ENDPOINTS.CREATE_RETELL_AGENT), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      agent: data.agent,
      llm: data.llm,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to create agent: ${errorText}`);
  }

  return response.json();
};

/**
 * Update an existing agent.
 * @param {string} agentId - The ID of the agent to update.
 * @param {Object} agentUpdate - The updated agent data.
 * @returns {Promise<Object>} The updated agent response.
 */
export const updateAgent = async (agentId, agentUpdate) => {
  const response = await fetch(
    getApiUrl(`${ENDPOINTS.UPDATE_AGENT}/${agentId}`),
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agentUpdate),
    }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to update agent: ${errorText}`);
  }

  return response.json();
};

/**
 * Fetch all voices.
 * @returns {Promise<Object>} The voices data.
 */
export const fetchVoices = async () => {
  const response = await fetch(getApiUrl(ENDPOINTS.VOICES));

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch voices: ${errorText}`);
  }

  return response.json();
};

/**
 * Fetch all LLMs.
 * @returns {Promise<Object>} The LLMs data.
 */
export const fetchLLMs = async () => {
  const response = await fetch(getApiUrl(ENDPOINTS.LLMS));

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch LLMs: ${errorText}`);
  }

  return response.json();
};

// You can add more API-related utility functions here if needed
