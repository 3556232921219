import React from 'react';
import IntegratedTrainingModule from './Landing';

const HealthTrainingModule = () => {
    const healthScenarios = [
        {
          title: "![medical scenario](https://blog.coursify.me/wp-content/uploads/2018/08/plan-your-online-course.jpg) Faith and Medicine",
          subtitle: "Managing Religious Beliefs in Critical Care",
          description: "Sarah Cohen, a 28-year-old primigravida and Jehovah's Witness, has undergone an emergency C-section and experienced severe postpartum hemorrhage. She's refusing blood transfusion based on her religious beliefs, despite showing signs of hypovolemic shock.",
          concepts: [
            "Effective communication in high-stress medical situations",
            "Applying principles of patient autonomy and informed consent",
            "Alternative treatments for blood loss management",
            "Navigating ethical dilemmas between religious beliefs and life-saving care",
            "Collaborating with medical teams for patient-centered care plans",
            "Managing personal biases in challenging patient decisions",
            "Understanding advance directives and ethics committees",
            "Demonstrating cultural competence for Jehovah's Witness patients"
          ],
          agentId: "agent_367d7a73b05158861d96131480",
          promptName: "generate_call_analysis_health_faith_medicine"
        },
        {
          title: "End of Life Management",
          subtitle: "Balancing Pain Management and Quality of Life",
          description: "Robert Chen, a 67-year-old with terminal pancreatic cancer, has been admitted to the palliative care unit due to increasing pain. His daughter, Lisa, is concerned about pain management and maintaining his quality of life in his final days.",
          concepts: [
            "Explaining complex medical concepts to patients and families",
            "Assessing concerns about pain management in end-of-life care",
            "Balancing pain relief and alertness in palliative care",
            "Developing comprehensive pain management plans",
            "Addressing emotional concerns and past traumas in care decisions",
            "Balancing medical best practices with patient preferences",
            "Demonstrating empathetic communication in end-of-life discussions",
            "Ongoing assessment and adjustment in palliative care"
          ],
          agentId: "agent_8f7776e43d743cd96f53ec5037",
          promptName: "generate_call_analysis_health_end_of_life"
        },
        {
          title: "Change Management: Implementing New EHR",
          subtitle: "Addressing Staff Concerns in System Transition",
          description: "Riverside Community Hospital is transitioning to a new Electronic Health Record (EHR) system. As the nurse manager, you must address the concerns of Zara, a senior nurse resistant to the change, and potentially convert her into a champion for the new system.",
          concepts: [
            "Effective change management strategies in healthcare",
            "Communicating benefits of EHR implementation",
            "Addressing concerns about patient care impact and workload",
            "Developing comprehensive training and support plans",
            "Engaging experienced staff in implementation processes",
            "Balancing hospital goals with staff concerns",
            "Demonstrating empathy and active listening skills",
            "Articulating long-term benefits while acknowledging short-term challenges"
          ],
          agentId: "agent_be8d6fe5d230a6bb6b7ebfc1fa",
          promptName: "generate_call_analysis_health_change_management"
        },
        {
          title: "Home is Where the Heart is",
          subtitle: "Discharge Planning for Advanced Heart Failure",
          description: "George Anderson, a 72-year-old man with advanced heart failure, is being discharged after his third hospitalization this year. You must conduct a comprehensive discharge education session with George and his family to ensure proper home care management.",
          concepts: [
            "Providing comprehensive discharge education for heart failure patients",
            "Communicating complex medical information to patients with varying health literacy",
            "Assessing and addressing barriers to adherence in home-based care",
            "Developing clear, manageable home care plans",
            "Responding to caregiver concerns and providing emotional support",
            "Demonstrating family-centered care skills",
            "Explaining signs and symptoms requiring immediate medical attention",
            "Collaborating with other healthcare team members"
          ],
          agentId: "agent_be099ed608f0825c9504b846ab",
          promptName: "generate_call_analysis_health_heart_failure_discharge"
        }
      ];
    
      return (
        <IntegratedTrainingModule
          sector="health"
          logo="/logo-health.png"
          title="Simulated Training for Nursing Students"
          subtitle="Advanced Healthcare Scenarios"
          description="Welcome to our advanced training module focusing on complex healthcare scenarios. These simulations are designed to test and enhance your skills in handling various medical challenges, ethical dilemmas, and patient care situations."
          scenarios={healthScenarios}
        />
      );
    };

export default HealthTrainingModule;
