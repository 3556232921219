import React, { useCallback } from 'react';
import { Table, Button } from '../UIComponents';
import { showErrorToast } from '../../utils/toast';

const ScenarioTable = ({
  pages,
  scenarios,
  selectedPageForScenarios,
  setSelectedPageForScenarios,
  fetchScenarios,
  onEditScenario,
  onDeleteScenario,
  retellAgents,
}) => {
  const handleEditClick = useCallback((scenario) => {
    console.log('ScenarioTable: Edit button clicked for scenario:', scenario);
    try {
      onEditScenario(scenario);
    } catch (error) {
      console.error('ScenarioTable: Error editing scenario:', error);
      showErrorToast('Failed to edit scenario. Please try again.');
    }
  }, [onEditScenario]);

  const handleDeleteClick = useCallback((scenarioId) => {
    console.log('ScenarioTable: Delete button clicked for scenario:', scenarioId);
    if (window.confirm('Are you sure you want to delete this scenario?')) {
      try {
        onDeleteScenario(scenarioId);
      } catch (error) {
        console.error('ScenarioTable: Error deleting scenario:', error);
        showErrorToast('Failed to delete scenario. Please try again.');
      }
    }
  }, [onDeleteScenario]);

  const handlePageSelect = useCallback((page) => {
    console.log('ScenarioTable: Selecting page:', page);
    setSelectedPageForScenarios(page);
    fetchScenarios(page.id);
  }, [setSelectedPageForScenarios, fetchScenarios]);

  if (!selectedPageForScenarios) {
    return (
      <div>
        <h3 className="text-h3 font-poppins font-medium mb-2 text-secondary-blue-1">
          Select a Page to View Scenarios
        </h3>
        <Table
          headers={['Path', 'Title', 'Actions']}
          columnWidths={['40%', '40%', '20%']}
          rows={pages.map((page) => [
            page.path,
            page.title,
            <div className="flex justify-center">
              <Button
                onClick={() => handlePageSelect(page)}
                variant="secondary"
                size="small"
              >
                Select
              </Button>
            </div>,
          ])}
        />
      </div>
    );
  }

  console.log('ScenarioTable: Rendering scenarios for page:', selectedPageForScenarios);
  console.log('ScenarioTable: Scenarios:', scenarios);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-h3 font-poppins font-medium text-secondary-blue-1">
          Scenarios for: {selectedPageForScenarios.title}
        </h3>
        <Button
          onClick={() => {
            console.log('ScenarioTable: Deselecting page');
            setSelectedPageForScenarios(null);
          }}
          variant="secondary"
          size="small"
        >
          Back to Pages
        </Button>
      </div>
      {scenarios.length === 0 ? (
        <p>No scenarios found for this page.</p>
      ) : (
        <Table
          headers={[
            'Title',
            'Subtitle',
            'Description',
            'Concepts',
            'Agent',
            'Prompt Name',
            'Actions',
          ]}
          columnWidths={['15%', '15%', '20%', '25%', '10%', '7%', '8%']}
          rows={scenarios.map((scenario) => [
            scenario.title,
            scenario.subtitle,
            <div className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
              {scenario.description}
            </div>,
            <div className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
              {scenario.concepts.join(', ')}
            </div>,
            retellAgents.find((agent) => agent.agent_id === scenario.agentId)?.agent_name || 'N/A',
            scenario.promptName,
            <div className="flex space-x-2 justify-center">
              <Button
                onClick={() => handleEditClick(scenario)}
                variant="secondary"
                size="small"
              >
                Edit
              </Button>
              <Button
                onClick={() => handleDeleteClick(scenario.id)}
                variant="danger"
                size="small"
              >
                Delete
              </Button>
            </div>,
          ])}
        />
      )}
    </div>
  );
};

export default ScenarioTable;