import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  Input,
  Button,
  CustomVoiceSelect,
} from "../UIComponents";
import { FaSpinner } from "react-icons/fa";

const AgentForm = ({
  isOpen,
  onClose,
  currentAgent,
  onCreateAgent,
  onUpdateAgent,
  retellVoices,
  retellLLMs = [],
  loadingVoices,
  loadingLLMs,
  error,
  loading,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      agent_name: "",
      voice_id: "",
      general_prompt: "You are a helpful AI assistant.",
    },
  });

  useEffect(() => {
    if (currentAgent) {
      const llm = currentAgent.llm || {};

      reset({
        agent_id: currentAgent.agent_id,
        agent_name: currentAgent.agent_name || "",
        voice_id: currentAgent.voice_id || "",
        general_prompt: llm.general_prompt || "You are a helpful AI assistant.",
        llm_id: llm.llm_id,
      });
    } else {
      reset({
        agent_name: "",
        voice_id: "",
        general_prompt: "You are a helpful AI assistant.",
      });
    }
  }, [currentAgent, reset]);

  const onSubmitForm = (data) => {
    if (currentAgent) {
      onUpdateAgent(data);
    } else {
      onCreateAgent(data);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      title={currentAgent ? "Edit Agent" : "Create New Agent"}
      maxHeight="80vh"
    >
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
        <Controller
          name="agent_name"
          control={control}
          rules={{ required: "Agent Name is required" }}
          render={({ field }) => (
            <Input
              {...field}
              label="Agent Name"
              placeholder="Enter Agent Name"
              error={errors.agent_name?.message}
              disabled={loading}
            />
          )}
        />
        <Controller
          name="voice_id"
          control={control}
          rules={{ required: "Voice is required" }}
          render={({ field }) => (
            <CustomVoiceSelect
              label="Voice"
              options={retellVoices.map((voice) => ({
                id: voice.voice_id,
                name: `${voice.voice_name} (${voice.accent}, ${voice.age})`,
                preview_audio_url: voice.preview_audio_url,
              }))}
              error={
                errors.voice_id?.message ||
                (retellVoices.length === 0 && !loadingVoices
                  ? "No voices available"
                  : "")
              }
              onSelect={(voiceId) => field.onChange(voiceId)}
              value={field.value}
              disabled={loadingVoices || loading || retellVoices.length === 0}
            />
          )}
        />
        {loadingVoices && (
          <div className="flex items-center justify-center">
            <FaSpinner className="animate-spin mr-2" />
            <span>Loading voices...</span>
          </div>
        )}
        {currentAgent && currentAgent.llm && (
          <div className="border p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">LLM Details</h3>
            <p>
              <strong>LLM ID:</strong> {currentAgent.llm.llm_id || "N/A"}
            </p>
            <p>
              <strong>WebSocket URL:</strong>{" "}
              {currentAgent.llm.llm_websocket_url || "N/A"}
            </p>
            <p>
              <strong>General Tools:</strong>{" "}
              {currentAgent.llm.general_tools
                ? currentAgent.llm.general_tools
                    .map((tool) => tool.name)
                    .join(", ")
                : "N/A"}
            </p>
          </div>
        )}
        <Controller
          name="general_prompt"
          control={control}
          rules={{ required: "General Prompt is required" }}
          render={({ field }) => (
            <div className="flex flex-col">
              <label htmlFor="general_prompt" className="mb-1 font-medium text-gray-700">
                General Prompt
              </label>
              <textarea
                {...field}
                id="general_prompt"
                rows={6}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 resize-none"
                placeholder="Enter General Prompt"
                disabled={loading}
              />
              {errors.general_prompt && (
                <p className="mt-1 text-sm text-red-500">{errors.general_prompt.message}</p>
              )}
              <p className="mt-1 text-sm text-gray-500">
                Describe the AI assistant's role and behavior
              </p>
            </div>
          )}
        />
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="primary"
            disabled={
              isSubmitting ||
              loading ||
              loadingVoices ||
              loadingLLMs ||
              retellVoices.length === 0
            }
            size="medium"
            className="w-full sm:w-auto flex items-center justify-center"
          >
            {(isSubmitting || loading) && <FaSpinner className="animate-spin mr-2" />}
            {currentAgent ? "Update Agent" : "Create Agent"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AgentForm;
