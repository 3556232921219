import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Input, Button } from '../UIComponents';
import { FaSpinner } from 'react-icons/fa';

const PageForm = ({ isOpen, onClose, onSubmit, currentPage, loading }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      path: '',
      title: '',
      subtitle: '',
      description: '',
      logo: '',
    },
  });

  useEffect(() => {
    if (currentPage) {
      reset({
        path: currentPage.path || '',
        title: currentPage.title || '',
        subtitle: currentPage.subtitle || '',
        description: currentPage.description || '',
        logo: currentPage.logo || '',
      });
    } else {
      reset({
        path: '',
        title: '',
        subtitle: '',
        description: '',
        logo: '',
      });
    }
  }, [currentPage, reset]);

  const onSubmitForm = (data) => {
    onSubmit(currentPage ? { ...data, id: currentPage.id } : data);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      title={currentPage ? 'Edit Page' : 'Create New Page'}
    >
      <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
        <Controller
          name="path"
          control={control}
          rules={{
            required: 'Path is required',
            pattern: {
              value: /^\/.*$/,
              message: 'Path must start with a "/"',
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Path"
              placeholder="/example-path"
              error={errors.path?.message}
            />
          )}
        />
        <Controller
          name="title"
          control={control}
          rules={{ required: 'Title is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Title"
              placeholder="Page Title"
              error={errors.title?.message}
            />
          )}
        />
        <Controller
          name="subtitle"
          control={control}
          rules={{ required: 'Subtitle is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Subtitle"
              placeholder="Page Subtitle"
              error={errors.subtitle?.message}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: 'Description is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Description"
              as="textarea"
              placeholder="Page Description"
              error={errors.description?.message}
            />
          )}
        />
        <Controller
          name="logo"
          control={control}
          rules={{
            required: 'Logo URL is required',
            pattern: {
              value: /^(ftp|http|https):\/\/[^ "]+$/,
              message: 'Enter a valid URL',
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Logo URL"
              placeholder="https://example.com/logo.png"
              error={errors.logo?.message}
            />
          )}
        />
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="primary"
            disabled={loading}
            size="medium"
            className="w-full sm:w-auto flex items-center"
          >
            {loading ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : null}
            {currentPage ? 'Update Page' : 'Create Page'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PageForm;