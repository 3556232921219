import React from 'react';
import IntegratedTrainingModule from './Landing';

const RenaissanceTrainingModule = () => {
    const renaissanceScenarios = [
        {
          title: "7th Grade Science Teacher",
          subtitle: "Selling Nearpod to Enhance Classroom Engagement",
          description: "Ms. Angela Martinez is a tech-savvy 7th-grade science teacher looking for ways to improve student engagement and real-time assessment capabilities. You must sell Nearpod, an interactive classroom engagement platform, addressing her specific needs and concerns.",
          concepts: [
            "Nearpod's interactive lesson delivery features",
            "Real-time assessment tools",
            "Integration with existing educational technologies",
            "Content creation and customization options",
            "Implementation and training process",
            "Understanding classroom engagement challenges",
            "Addressing concerns about implementation time",
            "Demonstrating ROI for individual teacher adoption",
            "Aligning product features with science education needs"
          ],
          agentId: "agent_2c5c634900cbb6d0e3bdf3a4ac",
          promptName: "generate_call_analysis_nearpod_science_teacher"
        },
        {
          title: "Principal of Maplewood Elementary School",
          subtitle: "Introducing Flocabulary for School-Wide Literacy Improvement",
          description: "Linda Green, the principal of Maplewood Elementary School, is seeking solutions to improve student engagement and literacy outcomes. Your task is to sell Flocabulary, an online learning platform using educational hip-hop music, while addressing implementation challenges and budget constraints.",
          concepts: [
            "Flocabulary's educational hip-hop content for vocabulary and literacy",
            "Interactive videos, activities, and assessments",
            "Curriculum alignment and standards compatibility",
            "Implementation and teacher training process",
            "Analytics and progress tracking features",
            "Addressing school-wide engagement and literacy challenges",
            "Demonstrating ease of implementation and teacher adoption",
            "Providing evidence of effectiveness in elementary settings",
            "Explaining ROI and budget considerations for school-wide adoption",
            "Aligning Flocabulary with existing curriculum"
          ],
          agentId: "agent_e965a6e5cdf1638fbbe61098d8",
          promptName: "generate_call_analysis_flocabulary_elementary_principal"
        },
        {
          title: "School Superintendent",
          subtitle: "Scaling Flocabulary Across a School District",
          description: "Dr. Robert Thompson, a school district superintendent, is looking for solutions to address district-wide concerns about student engagement and literacy improvement. You need to sell Flocabulary for large-scale implementation across multiple schools, addressing various stakeholder concerns.",
          concepts: [
            "Flocabulary's educational content across all grade levels",
            "Vocabulary and literacy improvement features",
            "District-wide implementation and scaling strategies",
            "Alignment with curriculum standards across multiple schools",
            "Analytics and tracking tools for district-level administrators",
            "Understanding district-wide educational challenges and goals",
            "Addressing concerns about large-scale implementation and training",
            "Demonstrating ROI for district-wide adoption",
            "Navigating budget considerations at the district level",
            "Customization options for district-specific needs and policies"
          ],
          agentId: "agent_ef5de4dc00a80b489cd713c7e5",
          promptName: "generate_call_analysis_flocabulary_district_superintendent"
        }
    ];
    
    return (
        <IntegratedTrainingModule
          sector="renaissance"
          logo="/logo-renaissance.png"
          title="Simulated Training for Educational Technology Sales"
          subtitle="Advanced Sales Scenarios in EdTech"
          description="Welcome to our advanced training module focusing on educational technology sales scenarios. These simulations are designed to test and enhance your skills in handling various sales challenges, from individual teacher adoption to district-wide implementation of educational platforms."
          scenarios={renaissanceScenarios}
        />
    );
};

export default RenaissanceTrainingModule;