import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StrategiModule from './Strategi';
import LawTrainingModule from './LawTraining';
import HealthTrainingModule from './HealthTraining';
import AdminDashboard from './pages/AdminDashboard'; // Updated import path
import RenaissanceTrainingModule from './RenaissanceTraining';
import DynamicPage from './DynamicPage';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-background-gray text-text-dark font-inter relative">
        <div className="absolute inset-0 bg-gradient-to-br from-background-gray to-primary-blue opacity-10 -z-10"></div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <Routes>
              <Route path="/strategi" element={<StrategiModule />} />
              <Route path="/law" element={<LawTrainingModule />} />
              <Route path="/health" element={<HealthTrainingModule />} />
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/renaissance" element={<RenaissanceTrainingModule />} />
              <Route path="/:path" element={<DynamicPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;