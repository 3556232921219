import { toast } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export const showSuccessToast = (message) => {
  toast.success(
    <div className="flex items-center">
      <FaCheckCircle className="mr-2" />
      {message}
    </div>
  );
};

export const showErrorToast = (message) => {
  toast.error(
    <div className="flex items-center">
      <FaTimesCircle className="mr-2" />
      {message}
    </div>
  );
};

export const handleError = (message, error) => {
  console.error(message, error);
  showErrorToast(`${message}: ${error.message}`);
};

// You can add more toast-related utility functions here if needed