import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IntegratedTrainingModule from './Landing';

const BASE_URL = 'https://nimo-backend.azurewebsites.net';

const DynamicPage = () => {
  const [pageData, setPageData] = useState(null);
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const { path } = useParams();

  useEffect(() => {
    console.log('Current path:', path);

    const fetchPageData = async () => {
      try {
        // Ensure the path has a leading slash
        const normalizedPath = path.startsWith('/') ? path : `/${path}`;
        const url = `${BASE_URL}/pages?path=${encodeURIComponent(normalizedPath)}`;
        console.log('Fetching page data from:', url);
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          console.log('Received page data:', data);
          if (data && data.length > 0) {
            setPageData(data[0]);
            if (data[0].id) {
              await fetchScenarios(data[0].id);
            } else {
              console.error('Page id is missing');
              setLoading(false);
            }
          } else {
            console.error('No page data found');
            setLoading(false);
          }
        } else {
          console.error('Failed to fetch page data');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching page data:', error);
        setLoading(false);
      }
    };

    const fetchScenarios = async (pageId) => {
      if (!pageId) {
        console.error('PageId is undefined or invalid');
        setLoading(false);
        return;
      }
      try {
        const url = `${BASE_URL}/scenarios?pageId=${pageId}`;
        console.log('Fetching scenarios from:', url);
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          console.log('Received scenarios:', data);
          setScenarios(data);
        } else {
          console.error('Failed to fetch scenarios');
        }
      } catch (error) {
        console.error('Error fetching scenarios:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [path]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!pageData) {
    return <div>Page not found</div>;
  }

  return (
    <IntegratedTrainingModule
      sector={pageData.path.replace('/', '')}
      logo={pageData.logo}
      title={pageData.title}
      subtitle={pageData.subtitle}
      description={pageData.description}
      scenarios={scenarios}
    />
  );
};

export default DynamicPage;