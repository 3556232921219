// components/UIComponents.js
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { FaPlay, FaPause, FaChevronDown } from 'react-icons/fa';

/**
 * Button Component
 * Supports variants, sizes, and disabled states.
 */
export const Button = ({
  children,
  className = '',
  variant = 'primary',
  disabled = false,
  size = 'medium',
  ...props
}) => {
  const baseStyles = 'rounded-button focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200 flex items-center justify-center font-inter font-medium';
  const sizeStyles = {
    small: 'text-small px-2 py-1 sm:px-3 sm:py-1',
    medium: 'text-body px-4 py-2 sm:px-6 sm:py-3',
    large: 'text-h3 px-6 py-3 sm:px-8 sm:py-4',
  };
  const variants = {
    primary: 'bg-primary-button text-text-light hover:bg-opacity-90 focus:ring-primary-button',
    secondary: 'bg-text-light text-secondary-blue-1 border border-secondary-blue-1 hover:bg-secondary-blue-1 hover:text-text-light focus:ring-secondary-blue-1',
    danger: 'bg-accent-orange text-text-light hover:bg-opacity-90 focus:ring-accent-orange',
    success: 'bg-secondary-blue-2 text-text-light hover:bg-opacity-90 focus:ring-secondary-blue-2',
  };
  const disabledStyles = 'opacity-50 cursor-not-allowed';

  return (
    <button
      className={`${baseStyles} ${sizeStyles[size]} ${variants[variant]} ${disabled ? disabledStyles : ''} ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

/**
 * Input Component
 * Supports displaying labels, error messages, and dynamic styling based on validation.
 */
export const Input = forwardRef(({ label, name, as = 'input', error, className = '', ...props }, ref) => {
  const Component = as;

  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor={name}
          className={`block mb-1 font-inter font-medium text-text-dark ${
            error ? 'text-accent-orange' : ''
          }`}
        >
          {label}
        </label>
      )}
      <Component
        id={name}
        name={name}
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={error ? `${name}-error` : undefined}
        className={`w-full px-3 py-2 border rounded-button focus:outline-none focus:ring-2 focus:ring-primary-blue font-inter text-body ${
          error ? 'border-accent-orange' : 'border-gray-300'
        } ${className}`}
        ref={ref}
        {...props}
      />
      {error && (
        <span
          id={`${name}-error`}
          className="text-accent-orange text-small mt-1 flex items-center font-inter"
        >
          <FaTimesCircle className="mr-1" />
          {error}
        </span>
      )}
    </div>
  );
});

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  as: PropTypes.elementType,
  error: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Select Component
 * Supports displaying labels, error messages, and dynamic styling based on validation.
 */
export const Select = ({
  label,
  name,
  options,
  error,
  className = '',
  ...props
}) => (
  <div className="mb-4">
    {label && (
      <label
        htmlFor={name}
        className={`block mb-1 font-inter font-medium text-text-dark ${
          error ? 'text-accent-orange' : ''
        }`}
      >
        {label}
      </label>
    )}
    <select
      id={name}
      name={name}
      aria-invalid={error ? 'true' : 'false'}
      aria-describedby={error ? `${name}-error` : undefined}
      className={`w-full px-3 py-2 border rounded-button focus:outline-none focus:ring-2 focus:ring-primary-blue font-inter text-body ${
        error ? 'border-accent-orange' : 'border-gray-300'
      } ${className}`}
      {...props}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name || option.title}
        </option>
      ))}
    </select>
    {error && (
      <span
        id={`${name}-error`}
        className="text-accent-orange text-small mt-1 flex items-center font-inter"
      >
        <FaTimesCircle className="mr-1" />
        {error}
      </span>
    )}
  </div>
);

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Card Component
 * A simple card layout component with shadow and rounded corners.
 */
export const Card = ({ children, className = '', ...props }) => (
  <div
    className={`bg-white shadow-card rounded-card overflow-hidden ${className}`}
    {...props}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * Table Component
 * Renders a table with headers and rows, supporting custom column widths and text wrapping.
 */
export const Table = ({ headers, rows, columnWidths = [], expandedRows = [] }) => {
  return (
    <div className="overflow-x-auto relative">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-background-gray">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-4 py-3 text-left text-small font-semibold text-text-dark uppercase tracking-wider font-inter"
                style={{ width: columnWidths[index] || 'auto' }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows.length > 0 ? (
            rows.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`px-4 py-4 text-body font-inter text-text-dark ${
                        cellIndex === 0 ? 'text-center' : ''
                      }`}
                      style={{ width: columnWidths[cellIndex] || 'auto' }}
                    >
                      <div className="whitespace-normal break-words">
                        {cell}
                      </div>
                    </td>
                  ))}
                </tr>
                {/* Render expanded row if present */}
                {expandedRows
                  .filter((expRow) => expRow.parentId === rows[rowIndex][1]?.props?.children)
                  .map((expRow, expIndex) => (
                    <tr key={`expanded-${rowIndex}-${expIndex}`}>
                      <td colSpan={headers.length} className="px-4 py-4">
                        {expRow.content}
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td
                colSpan={headers.length}
                className="px-4 py-4 whitespace-nowrap text-center text-body font-inter text-text-dark"
              >
                No data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
  columnWidths: PropTypes.arrayOf(PropTypes.string),
  expandedRows: PropTypes.arrayOf(
    PropTypes.shape({
      parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
};

/**
 * Tabs Component
 * Renders a set of tabs for navigation.
 */
export const Tabs = ({ tabs, activeTab, onChange }) => (
  <div className="border-b border-gray-200 overflow-x-auto">
    <nav className="-mb-px flex">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          className={`py-2 px-3 sm:px-4 text-center font-medium text-body border-b-2 font-inter whitespace-nowrap ${
            activeTab === tab.name
              ? 'border-primary-blue text-primary-blue'
              : 'border-transparent text-text-dark hover:text-primary-blue hover:border-primary-blue'
          }`}
          onClick={() => onChange(tab.name)}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * Modal Component
 * Renders a modal dialog with focus management and accessibility features.
 */
export const Modal = ({ isOpen, onClose, title, children, maxHeight }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      if (modalRef.current) {
        modalRef.current.focus();
      }
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-text-dark bg-opacity-50 flex items-center justify-center z-50 px-4 py-6 sm:p-0"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div
        ref={modalRef}
        tabIndex="-1"
        className="bg-white p-4 sm:p-6 rounded-card shadow-card w-full max-w-md focus:outline-none flex flex-col"
        style={{ maxHeight: maxHeight || '90vh' }}
      >
        <h2 id="modal-title" className="text-h3 sm:text-h2 font-semibold mb-4 flex items-center font-poppins text-primary-blue">
          <FaCheckCircle className="mr-2 text-primary-button" />
          {title}
        </h2>
        <div id="modal-description" className="mb-4 font-inter text-body text-text-dark overflow-y-auto flex-grow">
          {children}
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={onClose} variant="secondary" className="px-4 py-2">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
};

export const CustomVoiceSelect = ({ label, options, error, onSelect, value, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingId, setPlayingId] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const voice = options.find((option) => option.id === value);
    setSelectedVoice(voice || null);
  }, [value, options]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleSelect = (voice) => {
    setSelectedVoice(voice);
    onSelect(voice.id);
    setIsOpen(false);
  };

  const handleAudioPlayPause = (voice, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (currentAudio) {
      currentAudio.pause();
      setPlayingId(null);
    }
    if (playingId === voice.id) {
      setPlayingId(null);
    } else {
      const audio = new Audio(voice.preview_audio_url);
      setCurrentAudio(audio);
      audio.play();
      setPlayingId(voice.id);

      audio.onended = () => {
        setPlayingId(null);
      };
    }
  };

  return (
    <div className="relative">
      {label && (
        <label className="block mb-1 font-medium text-gray-700">{label}</label>
      )}
      <div
        onClick={toggleDropdown}
        className={`border px-3 py-2 rounded-lg cursor-pointer flex justify-between items-center bg-white shadow-sm ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {selectedVoice
          ? `${selectedVoice.name}`
          : 'Select a voice'}
        <FaChevronDown className={`ml-2 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {isOpen && !disabled && (
        <div
          className="absolute z-10 bg-white border mt-1 w-full rounded-lg shadow-lg max-h-60 overflow-y-auto"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <div
              key={option.id}
              className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100"
            >
              <span onClick={() => handleSelect(option)}>
                {option.name}
              </span>
              <button
                type="button"
                className="ml-2 text-blue-500 focus:outline-none"
                onClick={(e) => handleAudioPlayPause(option, e)}
                aria-label="Play audio"
                disabled={disabled}
              >
                {playingId === option.id ? <FaPause /> : <FaPlay />}
              </button>
            </div>
          ))}
        </div>
      )}

      {error && (
        <span className="text-red-500 text-sm mt-1 flex items-center">
          {error}
        </span>
      )}
    </div>
  );
};
