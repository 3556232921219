import React from 'react';
import { Table, Button } from '../UIComponents';

const PageTable = ({ pages, onEditPage, onDeletePage }) => {
  return (
    <Table
      headers={['Path', 'Title', 'Subtitle', 'Description', 'Logo', 'Actions']}
      columnWidths={['15%', '15%', '15%', '30%', '10%', '15%']}
      rows={pages.map((page) => [
        page.path,
        page.title,
        page.subtitle,
        page.description,
        <div className="flex justify-center">
          <img
            src={page.logo}
            alt={`${page.title} Logo`}
            className="h-8 w-8 object-contain"
          />
        </div>,
        <div className="flex space-x-2 justify-center">
          <Button
            onClick={() => onEditPage(page)}
            variant="secondary"
            size="small"
          >
            Edit
          </Button>
          <Button
            onClick={() => onDeletePage(page.id)}
            variant="danger"
            size="small"
          >
            Delete
          </Button>
        </div>,
      ])}
    />
  );
};

export default PageTable;