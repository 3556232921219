// src/pages/AdminDashboard.js

import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Tabs } from "../components/UIComponents";
import PageTable from "../components/Pages/PageTable";
import PageForm from "../components/Pages/PageForm";
import ScenarioTable from "../components/Scenarios/ScenarioTable";
import ScenarioForm from "../components/Scenarios/ScenarioForm";
import AgentForm from "../components/Agents/AgentForm";
import AgentTable from "../components/Agents/AgentTable";
import usePages from "../hooks/usePages";
import useScenarios from "../hooks/useScenarios";
import useAgents from "../hooks/useAgents";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { FaCheckCircle, FaPlus, FaSpinner } from "react-icons/fa";

const AdminDashboard = () => {
  // State for managing active tab
  const [activeTab, setActiveTab] = useState("pages");

  // State for modals
  const [isPageModalOpen, setIsPageModalOpen] = useState(false);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);

  // State for selected page in scenarios
  const [selectedPageForScenarios, setSelectedPageForScenarios] = useState(null);

  // Hooks for managing pages
  const {
    pages,
    currentPage,
    setCurrentPage,
    loadingPage,
    fetchPages,
    handleEditPage,
    handleDeletePage,
    handleSubmitPage,
  } = usePages();

  // Hooks for managing scenarios
  const {
    scenarios,
    currentScenario,
    setCurrentScenario,
    loadingScenario,
    fetchScenarios,
    handleEditScenario,
    handleDeleteScenario,
    handleSubmitScenario,
  } = useScenarios();

  // Hooks for managing agents
  const {
    retellAgents,
    retellVoices,
    retellLLMs,
    currentAgent,
    setCurrentAgent,
    loadingAgents,
    loadingAgent,
    loadingVoices,
    loadingLLMs,
    handleCreateAgent,
    handleUpdateAgent,
    handleEditAgent,
    handleDeleteAgent,
    error: agentsError,
  } = useAgents();

  // Fetch pages on component mount
  useEffect(() => {
    fetchPages();
    // Agents, voices, and LLMs are fetched within useAgents hook
  }, [fetchPages]);

  // Handler for editing a page
  const handlePageEditCallback = useCallback(
    (page) => {
      handleEditPage(page);
      setIsPageModalOpen(true);
    },
    [handleEditPage]
  );

  // Handler for editing a scenario
  const handleScenarioEditCallback = useCallback(
    async (scenario) => {
      try {
        await handleEditScenario(scenario);
        setIsScenarioModalOpen(true);
      } catch (error) {
        console.error("Error in handleScenarioEditCallback:", error);
        showErrorToast("Failed to edit scenario.");
      }
    },
    [handleEditScenario]
  );

  // Handler for submitting a scenario
  const handleScenarioSubmitCallback = useCallback(
    async (data) => {
      try {
        await handleSubmitScenario({
          ...data,
          pageId: selectedPageForScenarios.id,
        });
        setIsScenarioModalOpen(false);
        showSuccessToast("Scenario saved successfully.");
        if (selectedPageForScenarios) {
          fetchScenarios(selectedPageForScenarios.id);
        }
      } catch (error) {
        console.error("Error in handleScenarioSubmitCallback:", error);
        showErrorToast("Failed to save scenario.");
      }
    },
    [handleSubmitScenario, selectedPageForScenarios, fetchScenarios]
  );

  // Handler for editing an agent
  const handleAgentEditCallback = useCallback(
    (agent) => {
      handleEditAgent(agent);
      setIsAgentModalOpen(true);
    },
    [handleEditAgent]
  );

  // Handler for deleting an agent
  const handleAgentDeleteCallback = useCallback(
    async (agentId) => {
      try {
        await handleDeleteAgent(agentId);
        showSuccessToast("Agent deleted successfully.");
        // Agents list is refreshed within useAgents hook
      } catch (error) {
        console.error("Error in handleAgentDeleteCallback:", error);
        showErrorToast("Failed to delete agent.");
      }
    },
    [handleDeleteAgent]
  );

  // Handler for creating an agent
  const handleAgentCreateCallback = useCallback(
    async (data) => {
      try {
        await handleCreateAgent(data);
        setIsAgentModalOpen(false);
        showSuccessToast("Agent created successfully.");
        // Agents list is refreshed within useAgents hook
      } catch (error) {
        console.error("Error in handleAgentCreateCallback:", error);
        showErrorToast("Failed to create agent.");
      }
    },
    [handleCreateAgent]
  );

  // Handler for updating an agent
  const handleAgentUpdateCallback = useCallback(
    async (data) => {
      try {
        await handleUpdateAgent(data);
        setIsAgentModalOpen(false);
        showSuccessToast("Agent updated successfully.");
        // Agents list is refreshed within useAgents hook
      } catch (error) {
        console.error("Error in handleAgentUpdateCallback:", error);
        showErrorToast("Failed to update agent.");
      }
    },
    [handleUpdateAgent]
  );

  // Define tabs
  const tabs = [
    { name: "pages", label: "Manage Pages" },
    { name: "scenarios", label: "Manage Scenarios" },
    { name: "agents", label: "Manage Agents" },
  ];

  // Render content based on active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "pages":
        return (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-h2 font-poppins font-semibold text-primary-blue flex items-center">
                <FaCheckCircle className="mr-2 text-secondary-blue-1" />
                Manage Pages
              </h2>
              <Button
                onClick={() => {
                  setCurrentPage(null);
                  setIsPageModalOpen(true);
                }}
                variant="primary"
                size="medium"
                className="flex items-center"
              >
                <FaPlus className="mr-2" />
                Create New Page
              </Button>
            </div>
            <PageTable
              pages={pages}
              onEditPage={handlePageEditCallback}
              onDeletePage={handleDeletePage}
            />
          </div>
        );
      case "scenarios":
        return (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-h2 font-poppins font-semibold text-primary-blue flex items-center">
                <FaCheckCircle className="mr-2 text-secondary-blue-1" />
                Manage Scenarios
              </h2>
              <Button
                onClick={() => {
                  if (selectedPageForScenarios) {
                    setCurrentScenario(null);
                    setIsScenarioModalOpen(true);
                  } else {
                    showErrorToast("Please select a page first.");
                  }
                }}
                variant="primary"
                size="medium"
                className="flex items-center"
                disabled={!selectedPageForScenarios}
              >
                <FaPlus className="mr-2" />
                Create New Scenario
              </Button>
            </div>
            <ScenarioTable
              pages={pages}
              scenarios={scenarios}
              selectedPageForScenarios={selectedPageForScenarios}
              setSelectedPageForScenarios={setSelectedPageForScenarios}
              fetchScenarios={fetchScenarios}
              onEditScenario={handleScenarioEditCallback}
              onDeleteScenario={handleDeleteScenario}
              retellAgents={retellAgents}
            />
          </div>
        );
      case "agents":
        return (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-h2 font-poppins font-semibold text-primary-blue flex items-center">
                <FaCheckCircle className="mr-2 text-secondary-blue-1" />
                Manage Agents
              </h2>
              <Button
                onClick={() => {
                  setCurrentAgent(null);
                  setIsAgentModalOpen(true);
                }}
                variant="primary"
                size="medium"
                className="flex items-center"
              >
                <FaPlus className="mr-2" />
                Create New Agent
              </Button>
            </div>
            {loadingAgents || loadingVoices || loadingLLMs ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-primary-blue" size={24} />
                <p className="ml-2">Loading agents, voices, and LLMs...</p>
              </div>
            ) : agentsError ? (
              <div className="text-red-500">{agentsError}</div>
            ) : (
              <AgentTable
                agents={retellAgents}
                onEdit={handleAgentEditCallback}
                onDelete={handleAgentDeleteCallback}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-8">
      <h1 className="text-h1 font-poppins font-bold mb-8 text-center text-primary-blue">
        Admin Dashboard
      </h1>

      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      {/* Main Content Container */}
      <div className="max-w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        {/* Tabs Navigation */}
        <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />

        {/* Tab Content */}
        <div className="p-6">{renderTabContent()}</div>
      </div>

      {/* Page Form Modal */}
      <PageForm
        isOpen={isPageModalOpen}
        onClose={() => {
          setIsPageModalOpen(false);
          setCurrentPage(null);
        }}
        onSubmit={handleSubmitPage}
        currentPage={currentPage}
        loading={loadingPage}
      />

      {/* Scenario Form Modal */}
      <ScenarioForm
        isOpen={isScenarioModalOpen}
        onClose={() => {
          setIsScenarioModalOpen(false);
          setCurrentScenario(null);
        }}
        onSubmit={handleScenarioSubmitCallback}
        currentScenario={currentScenario}
        loading={loadingScenario}
        retellAgents={retellAgents}
        selectedPage={selectedPageForScenarios}
      />

      {/* Agent Form Modal */}
      <AgentForm
        isOpen={isAgentModalOpen}
        onClose={() => {
          setIsAgentModalOpen(false);
          setCurrentAgent(null);
        }}
        onCreateAgent={handleAgentCreateCallback}
        onUpdateAgent={handleAgentUpdateCallback}
        currentAgent={currentAgent}
        loading={loadingAgent}
        loadingVoices={loadingVoices}
        loadingLLMs={loadingLLMs}
        retellVoices={retellVoices}
        retellLLMs={retellLLMs}
        error={agentsError}
      />

      {/* Global Loading Overlay */}
      {(loadingPage || loadingScenario || loadingAgent) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex items-center">
            <FaSpinner className="animate-spin text-primary-blue mr-4" size={24} />
            <p className="text-lg font-semibold text-primary-blue">Loading...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
