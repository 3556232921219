import { useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleError } from '../utils/toast';

const API_BASE_URL = 'https://nimo-backend.azurewebsites.net/';

const usePages = () => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);

  const fetchPages = useCallback(async () => {
    console.log('Fetching pages...');
    setLoadingPage(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/pages`);
      console.log('Pages fetched:', response.data);
      setPages(response.data);
    } catch (error) {
      handleError('Failed to fetch pages', error);
    } finally {
      setLoadingPage(false);
    }
  }, []);

  const handleEditPage = (page) => {
    setCurrentPage(page);
  };

  const handleDeletePage = async (pageId) => {
    if (window.confirm('Are you sure you want to delete this page?')) {
      setLoadingPage(true);
      try {
        await axios.delete(`${API_BASE_URL}/pages/${pageId}`);
        toast.success('Page deleted successfully');
        fetchPages();
      } catch (error) {
        handleError('Failed to delete page', error);
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const handleSubmitPage = async (data) => {
    console.log('Submitting page:', data);
    setLoadingPage(true);
    try {
      if (currentPage) {
        await axios.put(`${API_BASE_URL}/pages/${currentPage.id}`, data);
        toast.success('Page updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/pages`, data);
        toast.success('Page created successfully');
      }
      fetchPages();
      setCurrentPage(null);
    } catch (error) {
      handleError('Failed to submit page', error);
    } finally {
      setLoadingPage(false);
    }
  };

  return {
    pages,
    currentPage,
    setCurrentPage,
    loadingPage,
    fetchPages,
    handleEditPage,
    handleDeletePage,
    handleSubmitPage,
  };
};

export default usePages;