import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Input, Select, Button } from '../UIComponents';
import { FaSpinner } from 'react-icons/fa';

const ScenarioForm = ({ isOpen, onClose, onSubmit, currentScenario, loading, retellAgents, selectedPage }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const prevIsOpenRef = useRef(isOpen);
  const prevCurrentScenarioRef = useRef(currentScenario);

  useEffect(() => {
    console.log('ScenarioForm: isOpen changed:', isOpen);
    console.log('ScenarioForm: currentScenario:', currentScenario);

    if (isOpen && (!prevIsOpenRef.current || currentScenario !== prevCurrentScenarioRef.current)) {
      console.log('ScenarioForm: Resetting form with currentScenario:', currentScenario);
      reset({
        title: currentScenario?.title || '',
        subtitle: currentScenario?.subtitle || '',
        description: currentScenario?.description || '',
        concepts: currentScenario?.concepts ? currentScenario.concepts.join(', ') : '',
        agentId: currentScenario?.agentId || '',
        promptName: currentScenario?.promptName || '',
      });
    }

    prevIsOpenRef.current = isOpen;
    prevCurrentScenarioRef.current = currentScenario;
  }, [isOpen, currentScenario, reset]);

  const onSubmitForm = async (data) => {
    console.log('ScenarioForm: Submitting form data:', data);
    setIsSubmitting(true);
    try {
      const formattedData = {
        ...data,
        concepts: data.concepts.split(',').map((concept) => concept.trim()),
        pageId: selectedPage.id,
      };
      await onSubmit(formattedData);
      console.log('ScenarioForm: Form submitted successfully');
    } catch (error) {
      console.error('ScenarioForm: Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    console.log('ScenarioForm: Closing modal and resetting form');
    onClose();
    reset();
  };

  console.log('ScenarioForm: Rendering with currentScenario:', currentScenario);
  console.log('ScenarioForm: Form errors:', errors);
  console.log('ScenarioForm: Form is valid:', isValid);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={currentScenario ? 'Edit Scenario' : 'Create New Scenario'}
    >
      <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
        <Controller
          name="title"
          control={control}
          rules={{ required: 'Title is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Title"
              placeholder="Scenario Title"
              error={errors.title?.message}
            />
          )}
        />
        <Controller
          name="subtitle"
          control={control}
          rules={{ required: 'Subtitle is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Subtitle"
              placeholder="Scenario Subtitle"
              error={errors.subtitle?.message}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: 'Description is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Description"
              as="textarea"
              placeholder="Scenario Description"
              error={errors.description?.message}
            />
          )}
        />
        <Controller
          name="concepts"
          control={control}
          rules={{
            required: 'At least one concept is required',
            validate: (value) =>
              value.split(',').every((concept) => concept.trim() !== '') ||
              'Concepts cannot be empty',
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Concepts (comma-separated)"
              placeholder="concept1, concept2, concept3"
              error={errors.concepts?.message}
            />
          )}
        />
        <Controller
          name="agentId"
          control={control}
          rules={{
            required: 'Agent selection is required',
            validate: (value) => value !== '' || 'Please select an agent',
          }}
          render={({ field }) => (
            <Select
              {...field}
              label="Select Agent"
              options={[
                { id: '', name: 'Select an Agent' },
                ...retellAgents.map((agent) => ({
                  id: agent.agent_id,
                  name: agent.agent_name,
                })),
              ]}
              error={errors.agentId?.message}
            />
          )}
        />
        <Controller
          name="promptName"
          control={control}
          rules={{ required: 'Prompt Name is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Prompt Name"
              placeholder="Enter Prompt Name"
              error={errors.promptName?.message}
            />
          )}
        />
        <div className="flex justify-end">
          <Button
            type="submit"
            variant="primary"
            disabled={loading || isSubmitting || !isValid}
            size="medium"
            className="w-full sm:w-auto flex items-center"
          >
            {(loading || isSubmitting) && <FaSpinner className="animate-spin mr-2" />}
            {currentScenario ? 'Update Scenario' : 'Create Scenario'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ScenarioForm;