import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleError } from '../utils/toast';

const API_BASE_URL = 'https://nimo-backend.azurewebsites.net/';

const useScenarios = () => {
  const [scenarios, setScenarios] = useState([]);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [loadingScenario, setLoadingScenario] = useState(false);

  useEffect(() => {
    console.log('Current scenario state:', currentScenario);
  }, [currentScenario]);

  const fetchScenarios = useCallback(async (pageId) => {
    console.log('Fetching scenarios for page:', pageId);
    setLoadingScenario(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/scenarios`, {
        params: { pageId },
      });
      console.log('Scenarios fetched:', response.data);
      setScenarios(response.data);
    } catch (error) {
      console.error('Error fetching scenarios:', error);
      handleError('Failed to fetch scenarios', error);
    } finally {
      setLoadingScenario(false);
    }
  }, []);

  const handleEditScenario = useCallback((scenario) => {
    console.log('handleEditScenario called with:', scenario);
    return new Promise((resolve) => {
      setCurrentScenario(prevScenario => {
        console.log('Updating currentScenario from:', prevScenario, 'to:', scenario);
        resolve();
        return scenario;
      });
    });
  }, []);

  const handleDeleteScenario = async (scenarioId) => {
    if (window.confirm('Are you sure you want to delete this scenario?')) {
      setLoadingScenario(true);
      try {
        await axios.delete(`${API_BASE_URL}/scenarios/${scenarioId}`);
        toast.success('Scenario deleted successfully');
        fetchScenarios(scenarios[0].pageId); // Assuming all scenarios in the list belong to the same page
      } catch (error) {
        console.error('Error deleting scenario:', error);
        handleError('Failed to delete scenario', error);
      } finally {
        setLoadingScenario(false);
      }
    }
  };

  const handleSubmitScenario = async (data) => {
    console.log('Submitting scenario:', data);
    setLoadingScenario(true);
    try {
      if (currentScenario) {
        const response = await axios.put(`${API_BASE_URL}/scenarios/${currentScenario.id}`, data);
        console.log('Scenario updated:', response.data);
        toast.success('Scenario updated successfully');
      } else {
        const response = await axios.post(`${API_BASE_URL}/scenarios`, data);
        console.log('Scenario created:', response.data);
        toast.success('Scenario created successfully');
      }
      await fetchScenarios(data.pageId);
      setCurrentScenario(null);
    } catch (error) {
      console.error('Error submitting scenario:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        handleError(`Failed to submit scenario: ${error.response.data.detail || error.message}`, error);
      } else {
        handleError('Failed to submit scenario', error);
      }
    } finally {
      setLoadingScenario(false);
    }
  };

  return {
    scenarios,
    currentScenario,
    setCurrentScenario,
    loadingScenario,
    fetchScenarios,
    handleEditScenario,
    handleDeleteScenario,
    handleSubmitScenario,
  };
};

export default useScenarios;